import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import 'bootstrap/dist/css/bootstrap.min.css'
import "../styles/style.scss"

export default function PageTemplate(data) {
    const pageData = data.data.allMarkdownRemark.edges[0].node
    console.log(pageData.frontmatter.title)
    if(pageData.frontmatter.featuredImage == null && pageData.frontmatter.pageImage == null){
      return <Layout pageTitle={pageData.frontmatter.title} pageContent={pageData.html} />
    } else if(pageData.frontmatter.featuredImage == null ){
      return <Layout pageTitle={pageData.frontmatter.title} pageContent={pageData.html} pageImage={pageData.frontmatter.pageImage.childImageSharp.fluid} />
    } else if(pageData.frontmatter.pageImage == null){
      return <Layout pageTitle={pageData.frontmatter.title} pageContent={pageData.html} 
        featuredImage={pageData.frontmatter.featuredImage.childImageSharp.fluid} />
    } else {
      return <Layout pageTitle={pageData.frontmatter.title} pageContent={pageData.html} 
        pageImage={pageData.frontmatter.pageImage.childImageSharp.fluid}
        featuredImage={pageData.frontmatter.featuredImage.childImageSharp.fluid} />
    }
}

export const query = graphql`query($slug: String!){
  allMarkdownRemark (filter: {fields:{slug: {eq: $slug}}}){
    edges {
      node{
        fields {
          slug
        }
      html
      frontmatter {
        title
        position
        pageImage{
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImage{
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
}`